.footer-info {
    color: #4a4a4a;
    margin-left: 25px;
    margin-right: 25px;
    line-height: 1.2;
    margin-top: 5px;
    margin-bottom: 0;
}

.footer-icon {
    color: #696969;
}
