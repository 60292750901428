.project {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.project-icon {
    width: 180px;
    height: 180px;
    /* border: 1px solid black; */
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.project-header {
    font-size: 1.25em;
}

.atx {
    color: black;
    border-radius: 10px;
}

.philasug {
    opacity: 0.6;
}

.project-info {
    margin-left: 30px;
    margin-right: 20px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.more-info {
    margin-top: 10px;
}

/* .more-info:hover {
    text-shadow: 0px 0px 2px blue;
    transition: text-shadow 0.15s ease;
} */

.tag-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.tag {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    height: 30px;
}
