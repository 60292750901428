.blog-post {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    list-style: none;
    margin-right: 40px;
    margin-bottom: 15px;
    text-decoration: none;
    padding: 10px;
    overflow: hidden;
    transition: height 0.3s;
    -webkit-transition: height 0.3s;
}

.blog-link {
    color: #0e58a1;
    text-decoration: none;

    display: inline-block;
    position: relative;
}

.blog-date {
    color: #4a4a4a;
    font-size: 0.9em;
    margin-top: 5px;
    margin-bottom: 0px;
}

.topic-button {
    border: 0px;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    width: 60px;
    height: 40px;
}

.topic-button:hover {
    background-color: white;
	cursor:pointer;
}
