.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.intro {
    font-family: 'Poppins', sans-serif;
    color: #28283b;
}

.intro-text {
    font-size: 1.5em;
    margin: auto;
}

.intro-desc {
    font-size: 1em;
    margin: auto;
    margin-bottom: 20px;
}

.intro-img {
    margin-top: 50px;
    width: 150px;
    height: 150px;
    clip-path: circle();
}

.contact-icon-header {
    font-size: 1.5em;
    color: #696969;
}

svg:hover path {
	fill: #969696;
	transition: all ease 0.2s;
}

svg path {
	transition: all ease 0.2s;
}
