.back-link {
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.25em;

    display: inline-block;
    position: relative;
}

.back-link p {
    display: inline-block;
    transition: 0.1s ease-in;
}

.back-link:hover p {
    transform: translateX(-3px);
}
