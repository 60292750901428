.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    list-style: none;
    width: 600px;
}

.nav-link {
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    /* font-weight: bold; */

    display: inline-block;
    padding: 15px 20px;
    position: relative;
}

.nav-link:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: black;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.nav-link:hover:after {
    width: 100%;
    left: 0;
}

.active {
    font-weight: bold;
}
