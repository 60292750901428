* {
    font-family: 'Lato', sans-serif;
}

.section {
    /* margin-left: 350px;
    margin-right: 350px; */
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.section-title {
    font-size: 1.5em;
    align-self: flex-start;
}

h1 {
    text-align: left;
    font-size: 2em;
}

.job-header,
.education-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.job-title h2,
.education-title h2 {
    margin: 0;
    font-size: 1.2em;
}

h4 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 1em;
    font-weight: normal;
}

.job-dates,
.education-dates {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0;
}

.job-experience,
.education {
    margin-bottom: 20px;
}

.description,
.contact-info {
    color: #4a4a4a;
    margin-left: 25px;
    margin-right: 25px;
    line-height: 1.2;
    margin-top: 5px;
    margin-bottom: 0;
}

a {
    color: light-blue;
    text-decoration: none;
}

a.visited {
    color: light-blue;
    text-decoration: none;
}

.contact-icon {
    color: #696969;
}
